import React from 'react';

import './hero.scss';

const Hero = () => {

    return (
        <div className="c-services-hero">
            <h1 className="c-services-hero__heading">Služby Business Elements</h1>
        </div>
    )
};

export default Hero;
