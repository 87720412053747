
export const useConsultingData = () => {
    return [
        {
            title: 'Umbrella strategie salesu & marketingu ',
            desc: 'Systematická restrukturalizace a nastavení vhodných procesů v oblasti salesu a marketingu ve společnosti v kooperaci s vedením společnosti. Taktéž vhodné řešení pro mentoring či zpětnou vazbu na TOP management společností a jejich personální audit.',
        },
        {
            title: 'Headhunting',
            desc: 'Executive search na míru náborové situaci a potřebám klienta s přímým oslovováním úzce profilovaných kandidátů. Efektivní náborové řešení šetřící čas i energii všech zúčastněných.',
        },
        {
            title: 'HR&recruitment services',
            desc: 'Nastavení metod výběrového řízení včetně re-modelace náborového procesu. Školení HR & Hiring managerů v oblasti Náboru a náborových technik. Výběr vhodných komunikačních kanálů v rámci daného oboru, HR Marketing a Employee Branding.',
        },
    ];
};
