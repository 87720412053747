import React from "react"
import Layout from '../components/Layout';
import Hero from '../components/services/Hero';
import {Consulting, CrisisManagement, Marketing, Sales} from '../components/services/Section'
import SEO from '../components/seo';

const Services = () => {

    return (
        <Layout>
            <SEO title="Marketing, obchod, management a konzultace | Business Elements" />

            <Hero />

            <Marketing />

            <Sales />

            <CrisisManagement />

            <Consulting />

        </Layout>
    )
};

export default Services
