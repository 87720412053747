import React, {useContext} from 'react';
import {useConsultingData} from './data';
import {useImages} from './images';
import Img from 'gatsby-image';
import {GlobalDispatchContext} from '../../../../context/GlobalContextProvider';

import '../section.scss';
import ConsultingIcon from '../../../../images/consulting-icon.png';

const Consulting = () => {
    const data = useConsultingData();
    const images = useImages();
    const dispatch = useContext(GlobalDispatchContext);

    return (
        <div className="c-services-section" id="consulting" data-animate-trigger={true}>
            <div className="c-services-section__inner">
                <div className="c-services-section__icon"
                     data-animate-target
                     data-animate-from={JSON.stringify({y: 40, opacity: 0})}
                     data-animate-to={JSON.stringify({y: 0, opacity: 1})}
                >
                    <img src={ConsultingIcon} alt=""/>
                </div>
                <h2 className="c-services-section__heading o-font-color-consulting"
                    data-animate-target
                    data-animate-from={JSON.stringify({y: 40, opacity: 0})}
                    data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .2})}
                >Consulting</h2>
                <p className="c-services-section__subheading"
                   data-animate-target
                   data-animate-from={JSON.stringify({y: 40, opacity: 0})}
                   data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .4})}
                >Získejte zpětnou vazbu a akční kroky na management Vaší společnosti, marketingové či obchodní strategie nebo na nábor a získávání nových sil do Vašich týmů</p>

                <div className="c-services-section__grid c-services-section__grid--consulting">
                    {data.map(({icon, title, desc}, index) => (
                        <>
                            {index === 0 && (
                                <div key={index}
                                     className="c-services-section__grid-image c-services-section__grid-image--one-cell"
                                     data-animate-target
                                     data-animate-from={JSON.stringify({y: 40, opacity: 0})}
                                     data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: index / 10 + .4})}
                                >
                                    <div className="c-services-section__grid-image-inner">
                                        <Img className='o-image' fluid={images.image1.childImageSharp.fluid} />
                                    </div>
                                </div>
                            )}
                            <div key={index}
                                 className="c-services-section__portlet"
                                 data-animate-target
                                 data-animate-from={JSON.stringify({y: 40, opacity: 0})}
                                 data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: index / 10 + .4})}
                            >
                                {icon && <span className="c-services-section__portlet-icon"><img src={icon} alt=""/></span>}
                                {title && <h3 className="c-services-section__portlet-heading">{title}</h3>}
                                {desc && <p className="c-services-section__portlet-desc">{desc}</p>}
                            </div>
                        </>
                    ))}
                </div>

                <div className="c-services-section__cta-button-wrap">
                    <button className="o-button" onClick={() => dispatch({ type: 'OPEN_MODAL' })}>Chci vědet víc</button>
                </div>
            </div>
        </div>
    );
};

export default Consulting;
