
export const useCMData = () => {
    return [
        {
            title: 'Krizové řízení a restrukturilizace',
            desc: 'Dočasné převzetí vedení podnikatelského subjektu nebo jedné z vnitřních unit společnosti s cílem odvrátit hrozící úpadek, ozdravení prostředí, naplnění KPI akcionářů či majitele společnosti a vytvoření prostředí důvěry. Standartní TOP manažerské řízení podniku/firmy po dobu časově neomezenou nedo dočasně (interim) k překlenutí určitého období. ',
        },
        {
            title: 'Krizové řízení firmy',
        },
        {
            title: 'Krizový manažer',
        },
        {
            title: 'Revitalizace a restrukturalizace firem',
        },
        {
            title: 'Restart firmy',
        },
        {
            title: 'Krizové řízení',
        },
        {
            title: 'Restrukturalizace firmy',
        },
        {
            title: 'Personálně krizový mgmt firem',
        },
    ];
};
