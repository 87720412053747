import SupportIcon from '../../../../images/support-icon.svg';
import DevelopmentIcon from '../../../../images/development-icon.svg';
import PresentationIcon from '../../../../images/presentation-icon.svg';
import StarsIcon from '../../../../images/stars-icon.svg';

export const useMarketingData = () => {
    return [
        {
            icon: SupportIcon,
            title: 'Podpora a servis webu',
            desc: 'V dnešní době každý web potřebuje podporu, odbornou konzultaci a pravidelný servis. Tuto službu pro Vás realizujeme komplexně, tudíž se nemusíte o nic starat a my vše vyřídíme a nastavíme za Vás.',
        },
        {
            icon: DevelopmentIcon,
            title: 'Tvorba webů na míru',
            desc: 'Vytváříme moderní webová řešení pro zákazníky všech velikostí ze všech oborů. Vytváříme složité portály i webové stránky pro drobné živnostníky. Nejlepší reklamou jsou pro nás reference od spokojených zákazníků. 75% nových zákazníků přichází na doporučení stávajících klientů.',
        },
        {
            title: 'Webové aplikace',
            desc: 'Potřebujete webovou aplikaci přesně podle Vašich představ a krabicová řešení Vám nedokážou vyhovět? Pojďme tedy vyvinout společně mobilní projekt na míru Vašich potřeb.',
        },
        {
            title: 'Mobilní aplikace',
            desc: 'Máte skvělý nápad na mobilní aplikaci nebo máte zavedený web a Vaši zákazníci by ocenili mobilní verzi dělanou na míru? Rádi Vám navrhneme řešení!',
        },
        {
            title: 'PPC',
            desc: 'Některé hledané výrazy jsou velice konkurenční a dostat se na první pozice v přirozeném vyhledávání je velice nákladné. Substitučním řešením může být využití PPC kampaní s kvalitním cílením na uživatele.',
        },
        {
            title: 'Analýza webu',
            desc: 'Odhalí hlavní nedostatky webu. Určí prioritní oblasti, do nichž je vhodné se pustit co nejdřív. Doporučí Vám vhodné řešení nebo navrhne ideální postup pro úpravy problematické oblasti.',
        },
        {
            title: 'Produkce (VIDEO&AUDIO)',
            desc: 'Komplexní produkci všech potřebných kampaňových assetů a podkladů pro komunikaci se zákazníkem. Specializace na video produkci (hraná tvorba, animace), foto produkci, audio produkci (rádio spoty, voiceovers) a copywriting. ',
        },
        {
            title: 'E-shopy',
            desc: 'Aby internetový obchod mohl splňovat veškeré požadavky majitele, je vhodné zvolit e-shop na míru.Tvorba e-shopu potom bude moci již od základů směřovat k nastavení cílů a splnění Vašich potřeb.',
        },
        {
            title: 'SEO',
            desc: 'Dlouhodobě stabilní rozvoj, reakce na aktuální chování uživatelů v digitálním prostředí. Optimalizace pro fulltextové vyhledávače představuje stále nejefektivnější způsob, jak zajistit a usnadnit zákazníkům cestu k Vám.',
        },
        {
            icon: StarsIcon,
            title: 'Creative marketing & idea making',
            desc: 'Základním stavebním kamenem úspěšných marketingových kampaní plnící obchodní cíle jsou: originální kreativní idea, domyšlená strategie a komunikace rezonující s cílovou skupinou. Pošleme Vaší značce svěží vítr do plachet novými nápady.',
        },
        {
            icon: PresentationIcon,
            title: 'Master prezentace - sales decks',
            desc: 'Potřebujete pomoci s unikátní obchodní či firemní prezentací pro Vaše klienty, zaměstnance nebo na konferenci? Pro exekutivce, korporace či interní týmy realizujeme profesionální, čisté a strukturované powerpoint prezentace na míru.',
        },
        {
            title: 'Sociální média & influenceři',
            desc: 'Návrh a realizace optimální strategie pro sociální sítě, včetně influencer marketingu specificky zaměřené na cíle klienta. Zviditelnění Vaší značky. Dodání důvěryhodnosti. Zapojení publika, budování silného vztahu s Vaší značkou. Silný důraz na akci (vyzkoušení, nákup, recenze).',
        },
    ];
};
