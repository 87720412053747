import {graphql, useStaticQuery} from 'gatsby';

export const useImages = () => {
    return useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "services/management.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `);
};
