
export const useSalesData = () => {
    return [
        {
            title: 'Obchodní strategie společnosti',
            desc: 'Komplexními kroky zanalyzujeme Vaši stávající obchodní strategii a navrhneme akční plán pro její zlepšení, vyšší efekvititu, utilizaci a lepší synergii s otatními odděleními.',
        },
        {
            title: 'Workshopy a školení',
            desc: 'Pro Vaše obchodní týmy či management rádi zrealizujeme workshopy/diskuzní prezentace/audity pro zlepšení jejich obchodních dovedností či nastavení a implementaci nové obchodní strategie.',
        },
        {
            title: 'Budgetting',
        },
        {
            title: 'Controlling',
        },
        {
            title: 'Interní komunikace',
        },
        {
            title: 'Business strategie',
        },
        {
            title: 'Business development',
        },
        {
            title: 'Finanční řízení a účetnictví',
        },
        {
            title: 'Business inteligence',
        },
        {
            title: 'Customer journey',
        },
    ];
};
